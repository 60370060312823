const columns = (renderCell, renderActions) => [
  {
    field: 'mw',
    headerName: 'MW (ppg)',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_600',
    headerName: 'FANN600',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_300',
    headerName: 'FANN300',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_200',
    headerName: 'FANN200',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_100',
    headerName: 'FANN100',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_6',
    headerName: 'FANN6',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_3',
    headerName: 'FANN3',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'gel_10min',
    headerName: 'GET 10 MIN (lb/100ft²)',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'gel_10sec',
    headerName: 'GET 10 SEC (lb/100ft²)',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'tank_vol',
    headerName: 'TANK VOLUME (bbl)',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
