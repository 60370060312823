import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';
function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}
export const listDrillString = (wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_LOADING,
  });
  const body = {
    query: {
      wellsInfoId: wellId
    }
  };
  return fetch
    .post('wells/entity/drillString/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
export const addNewDrillStringRow = () => (dispatch) => {
  const { wellsDrillString } = store.getState();
  const newDrillString = [...wellsDrillString.data];
  const nextId = newDrillString.length - 1;
  const newRow = {
    MD: '',
    drillstring_details: [],
    bit_details: [],
    lastUpdate: new Date(),
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newDrillString.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_SUCCESS,
    payload: {
      data: newDrillString,
      paginatedElements: newDrillString.length,
    },
  });
};
export const createDrillString = (body, wellId, details) => (dispatch) => {
  delete body._id;
  const input = {
    input: {
      ...body,
      wellsInfoId: wellId
    }
  };
  return fetch
    .post('wells/entity/drillString/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      if (details) {
        dispatch({
          type: REDUX_ACTIONS.WELLS_DRILL_STRING_SET_ID,
          payload: {
            data: data.data._id,
          },
        });
      }
      dispatch(
        enqueueSnackbar(
          'Drillstring Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillString(wellId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillString(wellId));
    });
};
export const updateDrillString = (body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_LOADING
  });
  const input = {
    query: {
      _id: body?._id
    },
    input: {
      ...body
    }
  };
  return fetch
    .put('wells/entity/drillString', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drillstring Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillString(body?.wellsInfoId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillString(body?.wellsInfoId));
    });
};
export const deleteDrillStringRow = (deleteDrillStringId, wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_LOADING
  });
  const body = {
    query: {
      _id: deleteDrillStringId
    }
  };
  return fetch
    .deleteAction('wells/entity/drillString', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drillstring deleted successfully',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillString(wellId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillString(wellId));
    });
};
export const updateDrillStringDetails = (data) => (dispatch) => {
  const { wellsDrillString } = store.getState();
  const currentDrillString = [...wellsDrillString.data];
  const index = currentDrillString.findIndex(drillstring => drillstring._id === data._id);
  currentDrillString[index] = data;

  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_SUCCESS,
    payload: {
      data: currentDrillString,
      paginatedElements: currentDrillString.length,
    },
  });
};
export const importDrillStringDetails = (data) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_LOADING,
  });
  const drillStringList = data.sort((a, b) => a.md - b.md).map((value, index) => {
    return {
      ...value,
      id: generateRandom(),
      grid_id: index,
      _id: index
    };
  });
  setTimeout(() => {
    dispatch({
      type: REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_SUCCESS,
      payload: {
        data: drillStringList,
        paginatedElements: drillStringList.length,
      }
    });
    dispatch(
      enqueueSnackbar(
        'CSV file imported Successfully.',
        'success',
        new Date().getTime() + Math.random()
      ));
  }, 2000);
};

export const listDrillStringDetails = (drillId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_LOADING,
  });
  const body = {
    query: {
      drillId
    }
  };
  return fetch
    .post('wells/entity/drillString/properties/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
export const createDrillStringDetailsProperty = (drillId, body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_LOADING
  });
  delete body._id;
  const input = {
    query: {
      drillId
    },
    input: body
  };
  return fetch
    .post('wells/entity/drillString/properties/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill String Details Property Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringDetails(drillId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringDetails(drillId));
    });
};
export const updateDrillStringDetailsProperty = (drillId, _id, body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_LOADING
  });
  const input = {
    query: {
      drillId,
      _id
    },
    input: body
  };
  return fetch
    .put('wells/entity/drillString/properties', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill String Details Property Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringDetails(drillId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringDetails(drillId));
    });
};
export const deleteDrillStringDetailsProperty = (drillId, _id) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_LOADING
  });
  const input = {
    query: {
      drillId,
      _id
    },
  };
  return fetch
    .deleteAction('wells/entity/drillString/properties', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill String Details Property Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringDetails(drillId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringDetails(drillId));
    });
};
export const listDrillStringBitDetails = (drillId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_LOADING,
  });
  const body = {
    query: {
      drillId
    }
  };
  return fetch
    .post('wells/entity/drillBit/properties/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
export const createDrillStringBitProperty = (drillId, body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_LOADING
  });
  const input = {
    query: {
      drillId
    },
    input: body
  };
  return fetch
    .post('wells/entity/drillBit/properties/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill Bit Property Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringBitDetails(drillId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringBitDetails(drillId));
    });
};
export const deleteDrillBitDetailsProperty = (drillId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_LOADING
  });
  const input = {
    query: {
      drillId,
    },
  };
  return fetch
    .deleteAction('wells/entity/drillBit/properties', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill Bit Property Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringBitDetails(drillId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringBitDetails(drillId));
    });
};
export const updateDrillStringBitDetailsProperty = (drillId, body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_LOADING
  });
  const input = {
    query: {
      drillId,
    },
    input: body
  };
  return fetch
    .put('wells/entity/drillBit/properties', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill Bit Details Property Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringBitDetails(drillId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillStringBitDetails(drillId));
    });
};
