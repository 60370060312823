const columns = (
  showColumn,
  renderCell,
  renderDataCell,
  renderReportsCell,
  renderAssetsCell,
  renderStatusCell,
  renderAlarmsCell,
  renderTruFlowCell,
  renderJobsRigEditCell,
  renderHydraulicCell,
  renderActions
) => [
  {
    field: 'Operator',
    headerName: 'Operator',
    width: 200,
    hide: true,
    renderCell,
  },
  {
    field: 'Well',
    headerName: 'Well',
    width: 240,
    hide: true,
    renderCell,
  },
  {
    field: 'Latitude',
    headerName: 'Latitude',
    width: 100,
    hide: true,
    renderCell,
  },
  {
    field: 'Longitude',
    headerName: 'Longitude',
    width: 100,
    hide: true,
    renderCell,
  },
  {
    field: 'Rig',
    headerName: 'Rig',
    width: 200,
    renderCell,
    hide: false,
    headerAlign: 'center',
    editable: true,
    renderEditCell: renderJobsRigEditCell,
  },
  {
    field: 'assets',
    headerName: 'Assets',
    renderCell: renderAssetsCell,
    headerAlign: 'center',
    hide: !showColumn,
  },
  {
    field: 'data',
    headerName: 'Data',
    renderCell: renderDataCell,
    headerAlign: 'center',
    hide: !showColumn,
    width: 200,
  },
  {
    field: 'report',
    headerName: 'Reports',
    renderCell: renderReportsCell,
    headerAlign: 'center',
    hide: !showColumn,
  },
  {
    field: 'systemStatus',
    headerName: 'Status',
    renderCell: renderStatusCell,
    headerAlign: 'center',
    hide: false,
  },
  {
    field: 'alarm',
    headerName: 'Alarms',
    renderCell: renderAlarmsCell,
    headerAlign: 'center',
    hide: false,
  },
  {
    field: 'hydraulic',
    headerName: 'Hydraulics',
    renderCell: renderHydraulicCell,
    headerAlign: 'center',
    hide: !showColumn,
    width: 150,
    align: 'center',
  },
  {
    field: 'latestTimestamp',
    headerName: 'Last Update',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'truFlow',
    headerName: 'Tru-Flow',
    width: 200,
    renderCell: renderTruFlowCell,
    headerAlign: 'center',
    align: 'center',
    // hide: !showColumn,
    hide: true
  },
  {
    field: 'usage',
    headerName: 'Usage',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'remainingLife',
    headerName: 'Remaining Life ',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
    hide: !showColumn,
  },
];

export default columns;
