import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  map: { height: '50vh', width: '100%', border: '5px solid white' },
  noActionPin: {
    cursor: 'initial',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  reportCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    justifyItems: 'center'
  },
  dividerStyle: {
    marginTop: '3%',
    marginBottom: '1.5%'
  },
}));

export default styles;
