import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import styles from './styles';

const SelectAutoComplete = (props) => {
  const { id, field, data, updateData } = props;
  const apiRef = useGridApiContext();
  const classes = styles();
  const [selectedValue, setSelectedValue] = React.useState(null);

  React.useEffect(() => {
    const initialValue = data.find((option) => option._id === id) || null;
    setSelectedValue(initialValue);
  }, [id, data]);

  const handleChange = (event, newValue) => {
    event.stopPropagation();
    event.preventDefault();
    updateData(id, field, newValue);
    setSelectedValue(newValue);
    if (props?.closeCellAfterSelect) {
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  const sortedData = data.sort((a, b) => {
    if (a.LeaseName < b.LeaseName) {
      return -1;
    }
    if (a.LeaseName > b.LeaseName) {
      return 1;
    }
    return 0;
  });

  return (
    <FormControl
      variant="filled"
      id="controlled-form"
      className={classes.inputField}
      sx={{ bgcolor: 'transparent' }}
    >
      <Autocomplete
        options={sortedData}
        value={selectedValue}
        getOptionLabel={(option) => {
          return option?.LeaseName + ' ' + option?.WellNumber;
        }}
        id="controlled-demo"
        onChange={handleChange}
        // isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField {...params} label="Select" variant="standard" InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }} />
        )}
      />
    </FormControl>
  );
};

SelectAutoComplete.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.any,
  data: PropTypes.any,
  updateData: PropTypes.func,
  closeCellAfterSelect: PropTypes.any
};

export default SelectAutoComplete;
