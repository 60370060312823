import React, { useEffect, useState } from 'react';
import ModalDialog from '../../ModalDialog';
import styles from '../styles';
import { Grid, IconButton } from '@mui/material';
import Datagrid from '../../Datagrid';
import PropTypes from 'prop-types';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import columns from './table_config';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { addNewHydraulicsDetailsRow, createHydraulicsDetails, deleteHydraulicsDetails, listHydraulicsDetails, updateHydraulicProperty, updateHydraulicsDetails } from '../../../../store/actions/hydraulics';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import SelectEditInputTextField from '../../SelectEditInputTextField';

const HydraulicsDetailsModal = ({
  onCloseClick,
  selectedJob,
  isOpen,
  data
}) => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const hydraulics = useSelector((state) => state.hydraulics);
  const dispatch = useDispatch();
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});

  useEffect(() => {
    if (!hydraulics.details.loading) {
      if (hydraulics.details.data?.length) {
        setProperties(hydraulics?.details?.data);
      } else {
        addHydraulicsDetails();
      }
    }

    return () => {
      setProperties([]);
    };
  }, [hydraulics.details]);

  useEffect(() => {
    if (properties[0]?.isNewRow === true) {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'hole_depth', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'hole_depth');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const addHydraulicsDetails = () => {
    dispatch(addNewHydraulicsDetailsRow());
  };

  const removeHydraulicsDetails = (row) => {
    const filteredProperties = properties.filter((item) => item._id !== row._id);
    const updatedHydraulicsData = {
      ...data,
      _id: hydraulics?.hydraulicsId ? hydraulics?.hydraulicsId : data?._id,
      run_details: filteredProperties
    };
    dispatch(updateHydraulicProperty(updatedHydraulicsData));
    setProperties(filteredProperties);
    if (!row?.isNewRow) {
      dispatch(deleteHydraulicsDetails(hydraulics?.hydraulicsId ? hydraulics?.hydraulicsId : data?._id));
    } else {
      dispatch(listHydraulicsDetails(hydraulics?.hydraulicsId ? hydraulics?.hydraulicsId : data?._id));
    }
  };

  const checkHydraulicsValues = (value) => {
    const row = value;
    const invalidFields = [];
    const isInvalidNumber = (val) => {
      // eslint-disable-next-line no-mixed-operators
      return isNaN(val) || val === '' || typeof val === 'string' && val.trim() === '';
    };

    if (row?.hole_depth === undefined || row?.hole_depth === null || isInvalidNumber(row.hole_depth)) {
      invalidFields.push('HOLE_DEPTH');
    }
    if (row?.bit_depth === undefined || row?.bit_depth === null || isInvalidNumber(row.bit_depth)) {
      invalidFields.push('BIT_DEPTH');
    }
    if (row?.rop === undefined || row?.rop === null || isInvalidNumber(row.rop)) {
      invalidFields.push('ROP');
    }
    if (row?.flow_in === undefined || row?.flow_in === null || isInvalidNumber(row.flow_in)) {
      invalidFields.push('FLOW_RATE');
    }

    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter valid numeric values for: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveProperty = (row) => {
    if (checkHydraulicsValues(row)) {
      const validationError = '';
      if (validationError === '') {
        const body = {
          hole_depth: Number(row.hole_depth),
          bit_depth: Number(row.bit_depth),
          rop: Number(row.rop),
          flow_in: Number(row.flow_in)
        };

        if (row?.isNewRow) {
          dispatch(createHydraulicsDetails(data?._id ? data?._id : hydraulics.hydraulicsId, body));
        } else {
          dispatch(updateHydraulicsDetails(data?._id ? data?._id : hydraulics.hydraulicsId, body));
        }

        const updatedData = {
          ...data,
          _id: hydraulics?.hydraulicsId ? hydraulics?.hydraulicsId : data?._id,
          run_details: body
        };

        dispatch(updateHydraulicProperty(updatedData));
      } else {
        if (validationError.length) {
          setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        }
        dispatch(
          enqueueSnackbar(
            validationError,
            'error',
            new Date().getTime() + Math.random()
          )
        );
      }
    }
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {`${selectedJob?.Rig} - ${data?.name}` || ''}
          </Grid>
          <Grid item>
          <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: Number(value)
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  const renderActionCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
       <IconButton
          variant="solid"
          disabled={hydraulics.details.loading}
          onClick={() => {
            setTimeout(() => {
              saveProperty(row);
            }, 100);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics.details.loading || row?.isNewRow}
          onClick={() => {
            removeHydraulicsDetails(row);
          }}
        >
            <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  return (
    <ModalDialog
    title={getModalTitle()}
    isOpen={isOpen}
    onClose={onCloseClick}
    dialogClass={classes.dialogModal}
  >
    <Grid container>
    <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
        </Grid>
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={properties}
          sx={{
            marginLeft: '-6px',
            '.MuiDataGrid-cell': {
              fontSize: '1rem',
              fontWeight: 900
            },
            '.MuiDataGrid-columnHeader': {
              fontSize: '1.2rem',
              fontWeight: '900 !important'
            }
          }}
          loading={hydraulics.details.loading}
          columns={columns(renderValueCell, renderActionCell)}
          onRowClick={(params, evt) => {
            evt.preventDefault();
            evt.stopPropagation();
          }}
          disableStickyHeader={false}
          autoHeight={false}
          autoheight
          height={'50vh'}
          getRowClassName={(params) => {
            return invalidRows[params.id] ? classes.invalidRow : '';
          }}
          pageSize={100}
          editMode="cell"
          toolbar={{
            options: {
              columns: false,
              filters: false,
              density: false,
              export: false
            },
          }}
        />
      </Grid>
    </Grid>
  </ModalDialog>
  );
};
HydraulicsDetailsModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
HydraulicsDetailsModal.defaultProps = {
  selectedJob: {}
};

export default HydraulicsDetailsModal;
