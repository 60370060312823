const columns = (renderCell, renderActions, renderEdit, renderWellsNameEditCell) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 450,
    editable: true,
    renderEditCell: renderWellsNameEditCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'operator',
    headerName: 'Operator',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'rig',
    headerName: 'Rig',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'latitude',
    headerName: 'Latitute',
    width: 200,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'longitude',
    headerName: 'Longitude',
    width: 200,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'surveys',
    headerName: 'Surveys',
    width: 100,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'geometry',
    headerName: 'Geometry',
    width: 100,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'drill_string',
    headerName: 'Drillstring',
    width: 100,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'drilling_fluid',
    headerName: 'Drilling Fluid',
    width: 100,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];

export default columns;
