const columns = (renderCell, RenderJsonField, renderActions, renderValueCell) => [
  {
    field: 'MD',
    headerName: 'MD (ft)',
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'details',
    headerName: 'Details',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      return <RenderJsonField value={data?.value?.length ? data?.value : {}} />;
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 250,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
